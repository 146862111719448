<template>
  <ion-list v-if="resources.data.length > 0">
    <ion-list-header>
      Chapters
    </ion-list-header>
    <ion-list
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
    >
      <ion-item lines="none">
        <ion-card class="full-width">
          <ion-item lines="none" class="ion-no-padding">
            <ion-card-header>
              <ion-card-title>{{ item.name }} </ion-card-title>
            </ion-card-header>
          </ion-item>
          <ion-card-content>
            
            {{ item.description }}
          </ion-card-content>
          <ion-item>
            <ion-button
              fill="solid"
              expand="full"
              :router-link="{
                name: 'chapter.progression',
                params: { chapter: item.id, description: description(item) },
              }"
              >Progression</ion-button
            >
          </ion-item>
        </ion-card>
      </ion-item>
    </ion-list>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No chapter found</p>
    </ion-list-header>
  </ion-list>
</template>
<script>
import {
  IonList,
  IonListHeader,
  IonItem,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from "@ionic/vue";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonItem,
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
  },
  data() {
    return {};
  },
  methods: {
    description(item) {
      return `Class ${item.subject.standard.name} ${item.subject.name}: ${item.name}`;
    },
  },
};
</script>
